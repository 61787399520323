@import "../imports";



#section-video{

    background:url(../img/banner-lp.jpg);
    background-position:center center;
    background-size:cover;
    background-repeat:no-repeat;
    height: 100%;

    
    margin-top: 80px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    width: 100%;
    display: block;
    &::before{
        content: '';
        position: absolute;
        z-index:0;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        background: rgb(0,0,0);
        background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(3, 40, 59, 0.85) 50%, rgba(3, 40, 59, 0.85) 100%);
        @media (max-width: 768px) {
            display: none;
        }
    }

    > div{
        position: relative;
        z-index: 2;
    }

    @media(max-width:1024px){
        height: auto;
    }
    @media (max-width: 768px) {
        height: auto;
        display: block;
        padding-bottom: 130px;
        .row.row-768{
            display:block !important;
        }
    }
    .bg{
        /*background-color: rgba(3, 40, 59, 0.85);*/
        height: 684px;
        display: flex;
        align-items: center;
        @media(max-width:768px){
            
            height: auto;
            margin-top: 15px;
            background-color: rgba(3, 40, 59, 0.85);
        }
    }
    .box-video .box-video{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
    }
    .link-video{
        position: relative;
        display: block;
        margin: 0 0 38px 0;

        @media(max-width:1024px){
            width: 100%;
            margin: 0 0 40px;
            img{
                width: 100%;
            }
        }
        &::before{
            content:"";
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.48);
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
        }
        svg{
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -49px 0 0 -49px;
            z-index: 2;
            transition: all 0.4s;
        }
        &:hover{
            svg{
                margin: -100px 0 0 -100px;
                width: 200px;
                height: 200px;
            }
        }
    }
    #formulario{
        max-width: $metade;
        padding: 0 43px 100px 43px;
        @media(max-width:768px){
            
            width: 100%;
            max-width: none;
            padding-bottom: 40px;
        }
        @media (max-width: 480px) {
            padding: 15px;
        }

        .msg{
            
            font-size: 27px;
            letter-spacing: -0.01em;
            text-align: center;
            color: #fff;
            display: block;
            margin-bottom: 33px;
            text-transform: uppercase;
            strong{
                font-weight: bold;
                display: block;

            }
            @media(max-width:768px){
            
                padding: 15px 0;
            }
            @media(max-width:480px){
            
                font-size: 20px;
                line-height: 20px;
            }

        }
        button{
            font-weight: normal;
            font-size: 21px;
            line-height: 30px;
            text-align: left;
            color: #f5e8df;
            margin: 33px 0;

            display: flex;
            align-items: center;
            justify-content: center;

            margin: 0 auto !important;
            border: none;
            height: 76px;
            width: calc(100% - 60px);
            float: right;
            background: $cor-principal;
            text-transform: uppercase;
            border-radius: 0;
            &:hover{
                background: $cor-secundaria
            
            }
            svg{
                margin-left: 10px;
            }
        }
        @media (max-width: 767px) {
            .bt{
                display: block;
                > div{
                    width: 100%;
                    button{
                        width: 100%;
                        margin: 20px auto 0 !important;
                        display: table;
                        padding: 0 20px;
                        float: none;
                        text-align: center;
                    }
                }
            }
        }
    }
    .label{
        position: absolute;
        bottom: 110px;
        left: 0;
        width: calc(50%);
        background: rgba(0, 0, 0, 0.63);
        @media (max-width: 768px) {
            position: relative;
            width: 100%;
            bottom: auto;
            margin: 20px 0 0;
            max-width: unset;
            display: block;
            padding: 20px 0;
            text-align: center;
        }

        
        span{
            max-width: $metade;
            
            font-weight: 500;
            font-size: 24px;
            line-height: 26px;
            text-align: left;
            color: #fff;
            padding: 41px 0;

           
            float:right;
            margin-right: 27px;
            /*width: calc((1270px / 2));*/
            strong{
                font-weight: 600;
            }
            @media(max-width:1024px){
                /*width: calc((960px / 2));*/
                float: none;
                margin-right: 0;

            }
            @media (max-width: 768px) {
                width: 100%;
                float: none;
                text-align: center;
                font-size: 20px;
                line-height: 20px;
                padding: 10px 16px;
                display: block;
            }
        }
    }

    
}
.galeria-imagens{
    margin-top: -100px;
    a{
        padding: 7px;
        background: #fff;
        position: relative;
        .fa{
            font-size: 16px;
        }
        span{
            position: absolute;
            padding: 20px;
            color:#fff;
            background: #6493A2;
            bottom: 18px;
            right: 18px;
        }
        &:hover{
            span{
                background: $cor-principal;

            }
        }
    }
    .lista{
        /* the slides */
        .slick-slide {
            margin: 0 16px;
        }
        /* the parent */
        .slick-list {
            margin: 0 -16px;
        }
        .slick-prev{
            left: 0;
            z-index: 2;
            background: $cor-principal;
            padding: 10px;
            width: 40px;
            height: 40px;
        }
        .slick-next{
            right: 0;
            background: $cor-principal;
            padding: 10px;
            width: 40px;
            height: 40px;
        }
        .slick-prev:before, .slick-next:before{
            
            z-index: 2;
        }
        img{
            width: 100%;
        }
    }
}
#terrenos{
    background:url(../img/bg-terrenos.png);
    background-position:center center;
    background-size:cover;
    background-repeat:no-repeat;
    height: 100%;
    height: 405px;
    display: flex;
    margin-top: 14px;
    @media(max-width:1024px){
        height: 200px;

    }
    @media (max-width: 768px) {
        height: auto;
        padding: 30px 0;
    }
    h3{
       
        font-size: 21px;
        line-height: 32px;
        text-align: center;
        color: #fff;
        display: block;
        strong{
            font-weight: 600;
        }
        @media (max-width: 768px) {
            font-size: 18px;
            line-height: 22px;

            br{
                display: none;
            }
        }

    }
}
.cta{
    button{
        display: table;
        margin: 20px auto 48px;

        font-size: 14px;
        line-height: 20px;
        text-align: left;
        color: #f5e8df;

        padding: 20px 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        height: 58px;
        background: $cor-principal;
        text-transform: uppercase;
        border-radius: 0;
        font-weight: 500;
        strong{
            font-weight: 600;
            padding-left: 10px;
        }
        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 20px;
        }
        @media (max-width: 480px) {
            padding: 10px;
            height: auto;
        }
        &:hover{
            background: $cor-secundaria
        }
        svg{
            margin-left: 20px;
            @media (max-width: 768px) {
                margin-left: 10px;
            }
        }
    }
}

#flutuante-whatsapp{
    cursor: pointer;
}

#section-listas{
    .lista{
        padding: 40px 0;
        .itens{
            display: flex;
            align-items: center;
            @media (max-width: 768px) {
                display: block;
            }
            .item-img{
                margin-top: -80px;
                img{
                    outline: 7px solid #fff !important;
                    outline-offset: -7px;
                    cursor: pointer;
                    @media (max-width: 768px) {
                        width: 100%;
                    }
                }
            }
            .item-texto{
                padding: 0 30px 0 0;

                h3{
                    font-weight: 500;
                    font-size: 25px;
                    line-height: 32px;
                    text-align: left;
                    color: #03283B;
                    font-weight: 500;
                    @media (max-width: 480px) {
                        text-align: center;
                    }
                }
               
                @media (max-width: 768px) {
                    padding: 0;
                }
                p{
                    margin-top: 20px;
                    font-size: 19px;
                    line-height: 23px;
                    font-weight: 400;
                    @media (max-width: 480px) {
                        text-align: center;
                    }
                }
                strong{
                    font-weight: 600;
                }
                
            }

            
        }
        &:nth-child(odd){
            background-color: #03283B;
            .item-texto{
                h3{
                    color: #fff;

                }
                color: #fff;
                order: 2;
                padding: 0 0 0 30px;
                @media (max-width: 768px) {
                    padding: 0;
                }

            }
            .item-img{
                order: 1;
            }
        }
    }
}
#section-condicoes{
    padding: 70px 0 0;
    background-color: #F8F8F8;
    .separador{
        display: flex;
        gap: 70px;
        @media (max-width: 768px) {
            display: block;
        }
        .logo{
            @media (max-width: 768px) {
                img{
                    display: block;
                    margin: 0 auto 20px;
                }
            }
        }
        .defesa{
            font-size: 21px;
            line-height: 32px;
            text-align: center;
            color: #03283b;
            strong{
                font-weight: bold;
            }
        }
    }
    .cta{
        padding: 1px;
    }
}
#section-infra{
    padding: 40px 0 0;
    h3{
        font-weight: 500;
        font-size: 25px;
        line-height: 32px;
        text-align: center;
        color: #03283b;
        margin-bottom: 40px;
    }
    img{
        margin: 0 auto;
        display: block;
    }
}
#section-localizacao{
    padding: 50px 0 10px;
    
    h3{
        font-weight: 500;
        font-size: 25px;
        line-height: 32px;
        text-align: left;
        color: #03283b;
        display: table;
        margin: 0 auto;
        position: relative;
        text-align: center;
        span{
            position: absolute;
            right: calc(100% + 20px);
            text-align: right;
            top: 0;

            font-weight: normal;
            font-size: 21px;
            line-height: 25px;
            letter-spacing: -0.06em;
            text-align: right;
            color: #242424;
            min-width: 400px;
            @media (max-width: 768px) {
                position: relative;
                right: auto;
                top: auto;
                text-align: center;
                display: block;
                min-width: auto;
            }

        }

    }
    .link{
        font-weight: normal;
        font-size: 21px;
        letter-spacing: -0.06em;
        line-height: 18px;
        text-align: right;
        color: #9d9d9d;
        float: right;
        @media (max-width: 768px) {
            float: none;
            text-align: center;
            display: table;
            margin: 10px auto;
        }
        &:hover{
            text-decoration: underline;
        }

    }
    iframe{
        width: 100%;
        height: 500px;
        margin-top: 27px;
        @media (max-width: 768px) {
            height: 400px;
        }
    }
}