$metade: calc((1320px / 2) - 30px);

@media(min-width:1400px){
      $metade: calc((1320px / 2) - 30px);
}

:root {
      --metade: calc((1320px / 2) - 30px);
      @media(min-width:1440px){
            --metade: calc((120px / 2) - 30px);
      }
    }